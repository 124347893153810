import React from "react"
import SEO from "../../components/seo"
import HeroFeaturePage from "../../components/pages/shared/hero/feature-page"
import FeaturesComponent from "../../components/pages/main/landing-features"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import Layout from "../../components/layout"
import BannerComponent from "../../components/pages/features/banner"
import GalleryComponent from "../../components/pages/features/gallery"
import FeaturesRow from "../../components/pages/features/row"
import FeaturesPricingCards from "../../components/pages/features/cards"
import Discount from "../../components/pages/main/discount"
import Breadcrumb from '../../components/controls/breadcrumbs'

const PortalFeaturePage = (props) => {
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'Возможности', url: '/feature'},
    { name: 'Кабинет клиента', url: ''}
  ];
  return (
    <Layout page={"portal"}>
      <SEO
        title="Личные кабинеты | CRM-система учета для детских центров"
        keywords={[`зарплаты сотрудников`, `расчет зарплат педагогов`, `расчет зарплат администраторов`]}
        description="В CRM для детских и учебных центров Параплан есть Личные кабинеты учеников. Индивидуальное расписание, онлайн оплаты, домашние задания для каждого ученика. Облачная CRM Параплан подходит для детских центров, франшиз, танцевальных студий, языковых центров, учебных центров и спортивных школ."
        url={"https://paraplancrm.ru" + props.location.pathname}
      />
      <HeroFeaturePage
        name={"portal"}
        title='Оформите доступ к&nbsp;личным кабинетам'
        description='Расписание, онлайн оплата и домашние задания теперь под&nbsp;рукой у ваших клиентов.'
        color='#6D20D2'
        image={require("../../images/cover/portal.png")} />
      <Breadcrumb crumbs={ crumbs } arrowcolor={"purple"}/>
      <GalleryComponent
        title={"Личный кабинет для каждого ученика"}
        galleryView={"video"}
        data={[
          {
            name: "schedule",
            title: "Удобное расписание",
            content: "Ученики могут проверить свое расписание в 2 клика и поставить напоминание о занятии в CRM с кабинетом клиента.",
            image: require("../../images/screenshot/portal/portal_gallery_schedule@2x.png"),
            video: require("../../video/schedule.mp4"),
          },
          {
            name: "homework",
            title: "Домашние задания",
            content: "Добавляйте домашние задания или ссылки на вебинары для учащихся студии танцев, творческих школ, центров или курсов. Они увидят их в личном кабинете. Ученики также получат уведомление на почту и смогут сразу приступить к просмотру.",
            image: require("../../images/screenshot/portal/portal_gallery_homework@2x.png"),
            video: require("../../video/homework.mp4"),
          },
          {
            name: "acquiring",
            title: "Онлайн-оплата",
            content: "Оплата абонементов легко осуществляется через личный кабинет. Теперь не надо тратить время администратора и искать наличные.",
            image: require("../../images/screenshot/portal/portal_gallery_acquiring@2x.png"),
            video: require("../../video/acquiring.mp4"),
          },
          {
            name: "finances",
            title: "Финансовая прозрачность",
            content: "В личном кабинете клиенты видят историю платежей и неоплаченных абонементов.",
            image: require("../../images/screenshot/portal/portal_gallery_finances@2x.png"),
            video: require("../../video/finances.mp4"),
          },
        ]}
      />
      {/*<FreeSummer topInd="60"/>*/}
      <Discount />
      <FeaturesRow
        features={[
          {
            name: "Проверка баланса ученика",
            text: "Положительный или отрицательный баланс доступен для просмотра и пополнения.",
            image: require("../../images/icons/portal_balance.svg"),
          },
          {
            name: "История посещений клиента",
            text: "Ученик видит все состоявшиеся занятия со своим участием.",
            image: require("../../images/icons/portal_attendance.svg"),
          },
          {
            name: "Отмены занятий в расписании",
            text: "Все изменения в расписании наглядно отображаются внутри кабинета.",
            image: require("../../images/icons/portal_cancellations.svg"),
          },
        ]} />
      <BannerComponent color={"green"} author={"Развивающий центр «Чиполлино»"} ctaText={"Подключить личные кабинеты"}>
        “Мы подключили личные кабинеты несколько месяцев назад. Через них наши клиенты оплачивают посещения и получают
        задания. Это оказалось очень легко и удобно. Спасибо команде Параплан, что делаете нашу жизнь проще!”
      </BannerComponent>
      <FeaturesPricingCards
        withMargins={true}
        title='Станьте ближе к своим клиентам!'
        subtitle='Стоимость функции "Личные кабинеты" вне зависимости от количества клиентов.' />
      <FeaturesComponent title="Дополнительные возможности" />
      <ContactFormComponent />
    </Layout>
  )
}

export default PortalFeaturePage
